<template>
  <div id="checkout">
    <v-dialog v-model="dialog"
      width="350px"
      transition="dialog-bottom-transition"
      scrollable
      :persistent="true"
    >
      <AddressList v-if="dialog" :shipping_promo="shipping_promo" @close='dialog = false'></AddressList>
    </v-dialog>
    <v-flex xs10 offset-xs1 v-if="cart && shipping" :class="isMobile ? 'checkout-m' : 'checkout'">
      <p v-if="!isMobile" class="text">{{ $t('order.subtitle2')}}:</p>

      <div>
        <v-layout wrap class="mt-5 mb-3">
          <v-flex xs12 sm12 md6>
            <span class="title"> {{ $t('order.shipping-method')}} </span>
              <v-layout wrap class="mt-2">
                <v-flex xs12 sm12 md9>
                  <v-flex xs9 sm9 md9 v-if="shipping" class="relative mb-8">
                    <img src="@/assets/self-pickup.png" alt="pickup" width="25px" class="shipping-img">
                    <span class="text shipping-label">
                      Self pick up
                    </span>
                  </v-flex>
                </v-flex>
              </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md6>
            <span class="title"> Pick up at </span>
            <div v-if="officeAddress && appBrand === '14'">
              <p class="text"> <b>{{ officeAddress.penerima }} </b></p>
              <p class="text"> {{ officeAddress.alamat_lengkap }}</p>
              <p class="text"> {{ officeAddress.subdistrict }}</p>
              <p class="text"> {{ officeAddress.city }} {{ officeAddress.kode_pos}}</p>
              <p class="text"> {{ officeAddress.province}}</p>
              <p class="text"> Indonesia </p>
              <!-- <p class="subtitle-gray"> {{ $t('order.phone')}}: {{ officeAddress.hp_penerima}}</p> -->
            </div>
            <div v-else>
              <p class="text"> <b> Fluffly Totebag Dreamscape </b></p>
              <p class="text"> The Crystal, GF, Senayan City</p>
            </div>
          </v-flex>
        </v-layout>

        <v-layout wrap class="mt-5 mb-3">
          <v-flex xs12 sm12 md6 class="mt-5">
            <span class="title" v-if="payment"> {{ $t('order.payment')}}</span>
            <div v-for="(item, index) in group" :key="index">
              <span v-if="item[0].category === 'MANUAL'" class="subtitle"> {{ $t('order.bank-transfer')}} {{ $t('order.manual')}}</span>
              <span v-else class="subtitle"> {{ item[0].category}} {{ $t('order.automatic')}}</span>
              <table class="tbl">
                <tbody v-for="(data, i) in item" :key="i">
                  <tr :class="!data.is_enable ? 'radio disable-row ' : 'radio'">
                    <td width="5%">
                      <input class="item" type="radio" v-model="selectedPayment" :id="data.id_payment" :value="data.id_payment" @click="setPaymentFee()" :disabled="!data.is_enable">
                    </td>
                    <td width="20%">
                      <img v-bind:src="data.logo" alt="img">
                    </td>
                    <td width="75%">
                      <label :for="data.id_payment">{{ data.name }}</label>
                    </td>
                  </tr>
                  <tr class="radio" v-if="selectedPayment === data.id_payment">
                    <td colspan="3" class="description" v-html="data.deskripsi"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </v-flex>

          <v-flex xs12 sm12 md6 class="order-detail mt-5">
            <span class="title"> {{ $t('order.detail')}}</span>
            <v-divider class="mt-2 mb-2"></v-divider>
            <v-layout wrap class="mt-5 mb-3" v-for="(item, i) in cart" :key="i">
              <v-flex xs5 sm5 md5 class="mt-1">
                <img v-bind:src="item.data.url_image[0]" class="responsive" alt="img">
              </v-flex>
              <v-flex xs5 sm5 md7 class="mt-1 ml-2">
                <p class="text"> {{ item.data.product_name }}</p>
                <p class="f-smaller mt-0"> {{ item.size.sku_product }} - {{ item.size.ukuran }}</p>
                <table class="tbl">
                  <tr>
                    <td class="text"> {{ addComma(item.size.selling_price) }} IDR</td>
                    <td class="text pr"> x{{ item.quantity }}</td>
                  </tr>
                </table>
              </v-flex>
            </v-layout>
            <v-divider class="mt-2 mb-2"></v-divider>
          </v-flex>
        </v-layout>

        <v-layout wrap class="mt-3 mb-3">
          <v-flex xs12 sm12 md6 class="mt-5" v-if="!isMobile">
          </v-flex>
          <v-flex xs12 sm12 md6>
            <!-- <v-layout wrap v-if="!is_voucher">
              <v-flex xs8 class="mt-5">
                <v-text-field
                  class="mb-0"
                  outlined
                  v-model="promo_code"
                  :label="$t('order.promo-code')"
                  ref="promo_code"
                  :messages="getPromoCodeMessage()"
                  :disabled="!selectedPayment || !shipping || promo.total_promo !== 0"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs3 offset-xs1 class="mt-5">
                <v-btn outlined color="black" width="100%" height="56px" @click="checkPromo()" :disabled="!selectedPayment || !shipping">
                  <span class="btn-text" v-html="promo.id_promo ? 'DELETE' : ' APPLY'"> </span>
                </v-btn>
              </v-flex>
            </v-layout>
            <div v-if="promo.description" class="subtitle-gray mb-5 pl-2 pt-2" style="border:1px solid green; color:green" v-html="promo.description"></div> -->
            <Voucher :total="total" :delivery_price="shipping.tarif" v-if="!is_promo" />
            <div class="amount">
              <table class="tbl">
                <tr>
                  <td class="text"> Subtotal :</td>
                  <td class="text pr"> {{ addComma(total) }} IDR</td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.shipping-fee')}} :</td>
                  <td class="text pr">
                    <span v-if="shipping"> {{ addComma(shipping.tarif) }} </span>
                    <span v-else> 0 </span>
                    IDR
                  </td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.service-fee')}} :</td>
                  <td class="text pr">
                    <span v-if="paymentFee"> {{ addComma(paymentFee) }} </span>
                    <span v-else> 0 </span>
                    IDR
                  </td>
                </tr>
                <tr>
                  <td class="text"> {{ $t('order.packing-fee')}} :</td>
                  <td class="text pr">
                    <span v-if="shipping"> {{ addComma(shipping.packing_fee) }} </span>
                    <span v-else> 0 </span>
                    IDR
                  </td>
                </tr>
                <!-- <tr>
                  <td class="text">
                    {{ $t('order.promo')}} : <br>
                    <span class="text-r">{{promo.name}}</span>
                  </td>
                  <td class="text-r pr"> - {{ addComma(promo.total_promo) }} IDR</td>
                </tr> -->
                <tr>
                  <td class="text">
                    Voucher : <br>
                    <span class="text-rv">{{voucher_name}}</span>
                  </td>
                  <td class="text-r pr"> - {{ addComma(voucher_price) }} IDR</td>
                </tr>
                <tr>
                  <td> <h3> TOTAL : </h3> </td>
                  <td class="pr"> <h3> {{ addComma((total + shipping.tarif + paymentFee + shipping.packing_fee - promo.total_promo - voucher_price)) }} IDR </h3> </td>
                </tr>
              </table>
            </div>
            <v-btn class="mt-5" tile :color="bgColor" dark width="100%" height="44px" @click="checkout()">
              <span class="btn-text"> CHECKOUT </span>
            </v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import _ from 'lodash'
  import Voucher from '@/components/Voucher'
  import AddressList from '@/components/AddressList'

  export default {
    name: 'Checkout',
    components: { Voucher,AddressList },
    computed: {
      ...mapState([
        'isMobile',
        'isLogin',
        'cart',
        'cartNumber',
        'token',
        'shipping',
        'delivery',
        'voucher',
        'bgColor',
        'officeAddress'
      ])
    },
    watch: {
      voucher (val) {
        if (val) {
          this.voucher_price = this.voucher.total_promo
          this.voucher_name = this.voucher.name
          this.is_voucher = true
        } else {
          this.voucher_name = ''
          this.is_voucher = false
          this.voucher_price = 0
        }
      }
    },
    data () {
      return {
        total: 0,
        payment: null,
        promo_code: '',
        promo: { total_promo : 0 },
        selectedPayment: '',
        paymentFee: 0,
        group: null,
        shipping_promo: 0,
        is_promo: false,
        is_voucher: false,
        voucher_price: 0,
        voucher_name: '',
        dialog: false,
        coversionResponse: '',
        appBrand:  process.env.VUE_APP_BRAND,
      }
    },
    methods: {
      openDialog () {
        this.dialog = true
      },
      getPromoCodeMessage (){
        if (!this.selectedPayment || !this.shipping) {
          this.$t('order.promo-message1')
          return this.$t('order.promo-message1')
        } else {
          if (this.promo_code) {
            if (this.promo.description) {
              return this.$t('order.promo-message2')
            } else {
              return this.$t('order.promo-message3')
            }
          } else {
            return this.$t('order.promo-message4')
          }
        }
      },
      setPaymentFee () {
        setTimeout(()=>{
          this.paymentFee = Number(this.payment.find(val => val.id_payment === this.selectedPayment).payment_fee)
        },500)
      },
      setTotalAmount () {
        this.total = 0
        this.cart.map(item => {
          this.total += (Number(item.size.selling_price) * item.quantity)
        })
        this.getPayment()
        if (this.voucher) {
          this.updateVoucherCode()
        }
      },
      getPayment () {
        if (this.shipping) {
          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token,
            total_transaction: this.total,
            delivery_price: this.shipping.tarif,
            is_offline: true
          }

          axios
            .get('/api/v1/orders/payment')
            .then(response => {
              this.payment = response.data.data
              this.groupingPayment(response.data.data)
            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      },
      checkPromo () {
        if (this.promo.id_promo) {
          this.promo_code = ''
          this.promo = { total_promo : 0 }
          this.shipping_promo = 0
          this.is_promo = false
        } else {
          this.$store.commit('setIsLoading', true)

          let body = {
            id_delivery_method: 1,
            kode_promo: this.promo_code,
            total_transaction: this.total,
            delivery_price: this.shipping.tarif,
            products:  this.cart.map(item => {
              return {
                sku_product: item.size.sku_product,
                product_code: item.data.product_code,
                qty: item.quantity
              }
            })
          }

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token,
            is_offline: true
          }

          axios
            .post('/api/v1/orders/promo', body)
            .then(response => {
              this.promo = response.data.data
              if (this.promo.type === 'ONGKIR') {
                this.shipping_promo = this.promo.total_promo
              }
              this.is_promo = true
            })
            .catch(error => {
              this.is_promo = false
              this.promo_code = ''
              this.promo = { total_promo : 0 }
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      },
      groupingPayment (data) {
        const filteredData = data.filter(item => item.category === 'QRIS')
        this.group = _.groupBy(filteredData, 'category')
      },
      checkout () {
        if (this.selectedPayment && this.shipping) {

          if (!this.promo_code || (this.promo_code && this.promo.description)) {
            this.$store.commit('setIsLoading', true)

            let products = this.cart.map(item => {
              return {
                id: item.size.sku_product,
                sku_product: item.size.sku_product,
                size: item.size.ukuran,
                product_name: item.data.product_name,
                product_code: item.data.product_code,
                quantity: item.quantity,
                qty: item.quantity
              }
            })

            axios.defaults.headers = {
              id_brand: process.env.VUE_APP_BRAND,
              token: this.token,
              is_offline: true
            }

            axios
              .post('/api/v1/orders/checkout_new',{
                id_delivery_method: 1,
                id_warehouse: 1,
                service: this.shipping.service,
                id_payment: this.selectedPayment,
                id_promo: this.setIdPromo(),
                kode_promo: this.setPromoCode(),
                use_promo: this.setPromoValue(),
                products: products
              })
              .then(response => {
                setTimeout(() => {
                  this.$router.push({ name: 'Order-Detail', params: { id: response.data.data.id_sales_header} })
                  this.$store.commit('setSnackbar', true)
                  this.$store.commit('setText', response.data.message)
                  this.$store.commit('setCart', [])

                  localStorage.removeItem('prepp_c')
                  localStorage.removeItem('prepp_v')
                  this.$store.commit('setVoucher', null)
                  this.$store.commit('setIsLoading', false)
                },3000)
              })
              .catch(error => {
                this.$store.commit('setIsLoading', false)
                this.errorHandling(error)
              })
          } else {
            this.$store.commit('setSnackbar', true)
            this.$store.commit('setText', this.$t('order.check-promo-code-first'))
          }
        } else {
          this.$store.commit('setSnackbar', true)
          this.$store.commit('setText', this.$t('order.pick-payment-method-first'))
        }
      },
      setIdPromo () {
        if (this.is_voucher) {
          return this.voucher.id_promo
        } else {
          if (this.is_promo) {
            return this.promo.id_promo ? '' : this.promo_code
          } else {
            return ''
          }
        }
      },
      setPromoCode () {
        if (this.is_voucher) {
          return this.voucher.kode_promo
        } else {
          if (this.is_promo) {
            return this.promo.id_promo ? '' : this.promo_code
          } else {
            return ''
          }
        }
      },
      setPromoValue () {
        if (this.is_voucher) {
          return true
        } else {
          if (this.is_promo) {
            return true
          } else {
            return false
          }
        }
      },
      updateVoucherCode () {
        this.$store.commit('setIsLoading', true)

        let body = {
          id_delivery_method: 1,
          kode_promo: this.voucher.kode_promo,
          total_transaction: this.total,
          delivery_price: this.shipping.tarif,
          products:  this.cart.map(item => {
            return {
              sku_product: item.size.sku_product,
              product_code: item.data.product_code,
              qty: item.quantity
            }
          })
        }

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token,
          is_offline: true
        }

        axios
          .post('/api/v1/orders/promo', body)
          .then(response => {
            localStorage.setItem('prepp_v', crypto.encrypt(response.data.data))
            this.$store.commit('setVoucher', response.data.data)
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      toPage (name) {
        if (this.$route.name !== name) {
          this.$router.push({ name: name});
        }
      }
    },
    mounted () {
      if (!this.isLogin) {
        this.$router.push({ name: 'Login'})
      } else {
        if (this.cart.length === 0) {
          this.$router.push({ name: 'Home'})
        } else {
          if (this.shipping) {
            this.setTotalAmount()
          } else {
            this.$router.push({ name: 'Cart'})
          }
        }

        if (this.voucher) {
          this.is_voucher = true
        }
      }

      this.setMetaInfo(null, this.$route.name)

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', false)
      this.$store.commit('setActivePage', 'CHECKOUT')
    }
  }
</script>

<style scoped>
  .checkout {
    border: 1px solid #C0C0C0;
    margin: 10% 0;
    padding: 20px;
  }

  .checkout-m {
    border: 1px solid #C0C0C0;
    margin: 20% 0;
    padding: 20px;
  }

  .tbl {
    width: 100%;
  }

  .text {
    color: #000000;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .text-r {
    color: red;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
  }

  .text-rv {
    color: red;
    margin-bottom: 2px;
    font-size: 0.6rem !important;
    font-style: normal !important;
  }

  .tracking-text {
    color: #808080;
    margin-bottom: 2px;
    font-size: 0.8rem !important;
    font-style: normal !important;
    cursor: pointer;
  }

  .title {
    color: #000000;
    font-weight: 900;
    font-size: 0.9rem !important;
    text-transform: uppercase;
  }

  .subtitle {
    color: #000000;
    font-size: 0.8rem !important;
    font-weight: bold;
  }

  .f-smaller {
    color: #808080;
    font-size: 0.7rem !important;
  }

  .subtitle-gray {
    color: #808080;
    margin-bottom: 0;
    font-size: 0.8rem !important;
  }

  .responsive {
    max-width: 125px !important;
    width: 100%;
    height: auto;
    position: relative;
    word-wrap: break-word;
  }

  .qr-code {
    max-width: 200px !important;
    width: 100%;
    height: auto;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .pr {
    float: right;
  }

  .red {
    color: red;
  }

  .order-detail {
    max-height: 500px;
    overflow-y: auto;
  }

  .amount {
    border: 1px solid #808080;
    padding: 15px;
  }

  .pointer {
    cursor: pointer;
  }

  .disable-row {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .radio {
    margin: 20px 0;
  }

  .radio .item {
    margin: 10px 10px;
    cursor: pointer;
  }

  .radio img {
    margin: 0 20px;
    width: 50px;
  }

  .radio label {
    font-size: 12px !important;
  }

  .radio .description {
    font-size: 12px;
    text-align: justify;
    text-justify: inter-word;
    border: 1px solid #c0c0c0;
    padding: 10px;
  }

  .line-thru {
    text-decoration: line-through;
  }

  .relative {
    position: relative;
  }

  .shipping-img {
    position: absolute;
    top: 3px;
    left: 0;
  }

  .shipping-label {
      position: absolute;
      top: 3px;
      left: 30px;
  }

</style>
